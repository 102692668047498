import { defineStore, acceptHMRUpdate } from 'pinia'
import { miscService } from '@/services/MiscService'
import { authenticationService } from '../services/AuthenticationService'

export const useMiscStore = defineStore('MiscStore', {
  state: () => {
    return {
      subsidiaries: [],
      subsidiary: {},
      currentOther: authenticationService.currentUserValue ? authenticationService.currentUserValue.other : null,
      subsidiaryId: authenticationService.currentUserValue.user.sbuUser ? authenticationService.currentUserValue.user.sbuUser.sbuId : null,
      frontlines: [],
      managers: [],
      operators: [],
      frontline: {},
      operator: {},
      manager: {},
      newManager: {
        sbuId: null,
        type: null,
        area: null
      },
      newFrontline: {
        expressCentreID: null,
        sbuId: null,
        type: null
      },
      newOperator: {
        hubId: null,
        airportId: null,
        type: null,
        sbuId: null
      },
      frontlineId: '',
      managerId: '',
      operatorId: '',
      operatorAccountType: [
        { type: 1, name: 'Debagging' },
        { type: 2, name: 'Transit' },
        { type: 3, name: 'Courier Supervisor' }
      ],
      accountType: [
        { type: 1, name: 'Retail' },
        { type: 2, name: 'Credit' },
        { type: 3, name: 'Key' }
      ],
      managerAccountType: [
        { type: 1, name: 'Station' },
        { type: 2, name: 'Regional' }
      ],
      centres: [],
      poslist: [],
      pos: {
        expressCentreId: null
      },
      centresByCity: [],
      stations: [],
      centre: {},
      centreCity: {},
      newCentre: {
        sbuId: null,
        cityId: null
      },
      allocations: [],
      centreId: '',
      cities: [],
      noAllocation: 0,
      city: {},
      newCity: {},
      newPos: {
        expressCentreId: null
      },
      cityId: '',
      townCityId: null,
      towns: [],
      town: {},
      newTown: {
        cityId: null
      },
      townId: '',
      airports: [],
      dssList: [],
      airport: {},
      airportId: '',
      newAirport: {},
      countries: [],
      country: {},
      newCountry: {},
      countryId: '',
      exceptions: [],
      exception: { name: '', type: 0 },
      exceptionId: '',
      hubs: [],
      hub: {},
      newHub: {
        cityId: 0,
        sbuId: 0
      },
      hubId: 0,
      prices: [],
      price: {},
      newPrice: {},
      priceId: '',
      accounts: [],
      account: {},
      newAccount: {},
      commPrices: [],
      commPrice: {},
      newCommPrice: {},
      commPriceId: '',
      templates: [],
      template: {},
      newTemplate: {},
      tempNames: [
        { value: 'Birthday', name: 'Birthdat Template' },
        { value: 'Anniversary', name: 'Anniversary Template' }
      ],
      templateId: {},
      newMessage: {},
      messageBody: '',
      type: 'Please Select',
      tempType: [
        { id: 0, name: 'Please Select' },
        { id: 1, name: 'SMS' },
        { id: 2, name: 'EMAIL' }
      ],
      drivers: [],
      driver: {},
      routines: [],
      routine: {},
      newRoutine: {
        sbuId: null,
        cityId: null
      },
      routineId: null,
      regions: [],
      region: {},
      newRegion: {
        sbuId: null
      },
      regionId: null,
      regionCities: [],
      regionCity: {},
      newRegionCity: {
        sbuId: null,
        cityId: null,
        regionId: null
      },
      regionCityId: null,
      airportCities: [],
      airportCity: {},
      newAirportCity: {
        cityId: null,
        airportId: null
      },
      airportCityId: null,
      expenseTypes: [],
      expenseType: {},
      newExpType: {
        sbuId: null
      },
      loading: false,
      searching: false,
      actionType: 'edit',
      deleteModal: false,
      updating: false,
      creating: false,
      removing: false,
      action: false,
      openAdd: false,
      searchParam: '',
      file: null,
      fileName: '',
      airportLoc: [
        { id: 1, name: 'Local' },
        { id: 2, name: 'International' }
      ],
      allHeads: [],
      error: null
    }
  },
  getters: {
    filteredStaffs: (state) => {
      let filteredStaffs = state.frontlines
      if (state.searchParam !== '' && state.searchParam) {
        filteredStaffs = filteredStaffs.filter((p) => {
          const firstName = p.user.firstName?.toLowerCase()
          const lastName = p.user.lastName?.toLowerCase()
          const centreName = p.expressCentre.name?.toLowerCase()
          return firstName?.includes(state.searchParam) || lastName?.includes(state.searchParam) || centreName?.includes(state.searchParam)
        })
      }
      return filteredStaffs
    },
    filteredManagers: (state) => {
      let filteredStaffs = state.managers
      if (state.searchParam !== '' && state.searchParam) {
        filteredStaffs = filteredStaffs.filter((p) => {
          const firstName = p.user.firstName?.toLowerCase()
          const lastName = p.user.lastName?.toLowerCase()
          return firstName?.includes(state.searchParam) || lastName?.includes(state.searchParam)
        })
      }
      return filteredStaffs
    },
    filteredOperators: (state) => {
      let filteredStaffs = state.operators
      if (state.searchParam !== '' && state.searchParam) {
        filteredStaffs = filteredStaffs.filter((p) => {
          const firstName = p.user.firstName?.toLowerCase()
          const lastName = p.user.lastName?.toLowerCase()
          return firstName?.includes(state.searchParam) || lastName?.includes(state.searchParam)
        })
      }
      return filteredStaffs
    },
    filteredCities: (state) => {
      let filteredCities = state.cities
      if (state.searchParam !== '' && state.searchParam) {
        filteredCities = filteredCities.filter((c) => {
          const abbr = c.abbr?.toLowerCase()
          const name = c.name?.toLowerCase()
          return abbr?.includes(state.searchParam) || name?.includes(state.searchParam)
        })
      }
      return filteredCities
    },
    filteredCountries: (state) => {
      let filteredCountries = state.countries
      if (state.searchParam !== '' && state.searchParam) {
        filteredCountries = filteredCountries.filter((c) => {
          const abbr = c.abbr?.toLowerCase()
          const name = c.name?.toLowerCase()
          return abbr?.includes(state.searchParam) || name?.includes(state.searchParam)
        })
      }
      return filteredCountries
    },
    filteredTowns: (state) => {
      let filteredTowns = state.towns
      if (state.searchParam !== '' && state.searchParam) {
        filteredTowns = filteredTowns.filter((c) => {
          const abbr = c.abbr?.toLowerCase()
          const name = c.name?.toLowerCase()
          return abbr?.includes(state.searchParam) || name?.includes(state.searchParam)
        })
      }
      return filteredTowns
    },
    filteredAirports: (state) => {
      let filteredAirports = state.airports
      if (state.searchParam !== '' && state.searchParam) {
        filteredAirports = filteredAirports.filter((c) => {
          const abbr = c.abbr?.toLowerCase()
          const name = c.name?.toLowerCase()
          return abbr?.includes(state.searchParam) || name?.includes(state.searchParam)
        })
      }
      return filteredAirports
    },
    filteredBanks: (state) => {
      let filteredBanks = state.accounts
      if (state.searchParam !== '' && state.searchParam) {
        filteredBanks = filteredBanks.filter((c) => {
          const abbr = c.accountNumber?.toLowerCase()
          const name = c.bankAccountName?.toLowerCase()
          const id = c.bankAccountId?.toLowerCase()
          return abbr?.includes(state.searchParam) || name?.includes(state.searchParam) || id?.includes(state.searchParam)
        })
      }
      return filteredBanks
    },
    filteredCentres: (state) => {
      let filteredCentres = state.centres
      if (state.searchParam !== '' && state.searchParam) {
        filteredCentres = filteredCentres.filter((c) => {
          const abbr = c.abbr?.toLowerCase()
          const name = c.name?.toLowerCase()
          return abbr?.includes(state.searchParam) || name?.includes(state.searchParam)
        })
      }
      return filteredCentres
    },
    filteredCentresCity: (state) => {
      let filteredCentres = state.centresByCity
      if (state.searchParam !== '' && state.searchParam) {
        filteredCentres = filteredCentres.filter((c) => {
          const abbr = c.expressCentre.abbr?.toLowerCase()
          const name = c.expressCentre.name?.toLowerCase()
          return abbr?.includes(state.searchParam) || name?.includes(state.searchParam)
        })
      }
      return filteredCentres
    },
    filteredPoslist: (state) => {
      let filteredPoslist = state.poslist
      if (state.searchParam !== '' && state.searchParam) {
        filteredPoslist = filteredPoslist.filter((c) => {
          const abbr = c.aliasName?.toLowerCase()
          const name = c.description?.toLowerCase()
          return abbr?.includes(state.searchParam) || name?.includes(state.searchParam)
        })
      }
      return filteredPoslist
    },
    filteredCommPrices: (state) => {
      let filteredPrices = state.commPrices
      if (state.searchParam !== '' && state.searchParam) {
        filteredPrices = filteredPrices.filter((c) => {
          const name = c.name?.toLowerCase()
          return name?.includes(state.searchParam)
        })
      }
      return filteredPrices
    },
    filteredHubs: (state) => {
      let hubsFiltered = state.hubs
      if (state.searchParam !== '' && state.searchParam) {
        hubsFiltered = hubsFiltered.filter((h) => {
          const name = h?.name?.toLowerCase()
          return name.includes(state.searchParam)
        })
      }
      return hubsFiltered
    },
    filteredRoutines: (state) => {
      let routinesFiltered = state.routines
      if (state.searchParam !== '' && state.searchParam) {
        routinesFiltered = routinesFiltered.filter((r) => {
          const name = r?.name?.toLowerCase()
          return name.includes(state.searchParam)
        })
      }
      return routinesFiltered
    }
  },
  actions: {
    getSubsidiaries () {
      miscService.getSBUs()
        .then(
          s => {
            this.subsidiaries = s
            console.log('subs >>', s)
          }
        )
    },
    // getHubs () {
    //   miscService.getHubs(this.subsidiaryId)
    //     .then(
    //       s => {
    //         this.hubs = s
    //         console.log('subs >>', s)
    //       }
    //     )
    // },
    getAirports () {
      this.loading = true
      miscService.getAirports()
        .then(
          a => {
            this.airports = a
            const keys = Object.keys(a[0])
            this.allHeads = keys.map(key => key.toString())
            console.log('allFields >>', this.allHeads)
            console.log('airports', a)
            this.loading = false
          },
          error => {
            console.log(error)
            // alert(error)
          }
        )
    },
    getCountries () {
      this.loading = true
      miscService.getCountries()
        .then(
          c => {
            this.countries = c
            console.log('countries', c)
            this.loading = false
          },
          error => {
            console.log(error)
            // alert(error)
          }
        )
    },
    getCities () {
      this.loading = true
      miscService.getCities()
        .then(
          c => {
            this.cities = c
            console.log('cities', c)
            this.loading = false
          },
          error => {
            console.log(error)
            this.loading = false
            // alert(error)
          }
        )
    },
    getTowns (id) {
      this.loading = true
      this.searching = true
      miscService.getTowns(id)
        .then(
          t => {
            this.towns = t
            console.log('towns', t)
            this.loading = false
            this.searching = false
          },
          error => {
            console.log(error)
            alert(error)
            this.loading = false
            this.searching = false
          }
        )
    },
    getHubs (id) {
      this.loading = true
      miscService.getHubs(id)
        .then(
          h => {
            this.hubs = h
            console.log('hubs', h)
            this.loading = false
          },
          error => {
            console.log(error)
            alert(error)
            this.loading = false
          }
        )
    },
    getCommodityPrices () {
      this.loading = true
      miscService.getPrices()
        .then(
          p => {
            this.prices = p
            console.log('cPrices', p)
            this.loading = false
          },
          error => {
            console.log(error)
            // alert(error)
          }
        )
    },
    getBankAccounts (id) {
      this.loading = true
      miscService.getBanks(id)
        .then(
          b => {
            this.accounts = b
            console.log('cPrices', b)
            this.loading = false
          },
          error => {
            console.log(error)
            // alert(error)
          }
        )
    },
    getFrontlines (id) {
      this.loading = true
      miscService.getFrontlines(id)
        .then(
          f => {
            this.frontlines = f
            console.log('frontlines >>', f)
            this.loading = false
          },
          error => {
            console.log(error)
            // alert(error)
            this.loading = false
          }
        )
    },
    getManagers (id) {
      this.loading = true
      miscService.getManagers(id)
        .then(
          f => {
            this.managers = f
            console.log('managers >>', f)
            this.loading = false
          },
          error => {
            console.log(error)
            // alert(error)
            this.loading = false
          }
        )
    },
    getOperators (id) {
      this.loading = true
      miscService.getOperators(id)
        .then(
          f => {
            this.operators = f
            console.log('operators >>', f)
            this.loading = false
          },
          error => {
            console.log(error)
            // alert(error)
            this.loading = false
          }
        )
    },
    getGSAOperators () {
      this.loading = true
      miscService.getGSAOperators()
        .then(
          f => {
            this.operators = f
            console.log('operators >>', f)
            this.loading = false
          },
          error => {
            console.log(error)
            // alert(error)
            this.loading = false
          }
        )
    },
    getCentres (id) {
      this.loading = true
      miscService.getCentres(id)
        .then(
          c => {
            this.centres = c
            console.log('centres >>', c)
            this.loading = false
          },
          error => {
            console.log(error)
            // alert(error)
            this.loading = false
          }
        )
    },
    getCentresByCity (sbuId, id) {
      this.loading = true
      miscService.getCentresByCity(id, sbuId)
        .then(
          c => {
            this.centresByCity = c
            console.log('centres >>', c)
            this.loading = false
          },
          error => {
            console.log(error)
            // alert(error)
            this.loading = false
          }
        )
    },
    getCentrePOSsBysbu (id) {
      this.loading = true
      miscService.getCentrePOSsBysbu(id)
        .then(
          c => {
            this.poslist = c
            console.log('poslist >>', this.poslist)
            this.loading = false
          },
          error => {
            console.log(error)
            // alert(error)
            this.loading = false
          }
        )
    },
    getStations () {
      this.loading = true
      miscService.getCentresByCity(this.currentOther.hub.cityId)
        .then(
          c => {
            this.stations = c
            console.log('centres >>', c)
            this.loading = false
          },
          error => {
            console.log(error)
            // alert(error)
            this.loading = false
          }
        )
    },
    getCommPrices () {
      this.loading = true
      miscService.getCommodityPrices()
        .then(
          c => {
            this.commPrices = c
            console.log('commPrices >>', c)
            this.loading = false
          },
          error => {
            console.log(error)
            // alert(error)
            this.loading = false
          }
        )
    },
    getTemplates () {
      this.loading = true
      miscService.getTemplates()
        .then(
          t => {
            this.templates = t
            console.log('templates >>', t)
            this.loading = false
          },
          error => {
            console.log(error)
            // alert(error)
            this.loading = false
          }
        )
    },
    getRegions (id) {
      this.searching = true
      miscService.getRegions(id)
        .then(
          r => {
            this.regions = r
            console.log('regions >>', r)
            this.searching = false
          },
          error => {
            console.log(error)
            // alert(error)
            this.searching = false
          }
        )
    },
    getRegion (id) {
      this.loading = true
      miscService.getRegion(id)
        .then(
          r => {
            this.region = r
            console.log('region >>', r)
            this.loading = false
          },
          error => {
            console.log(error)
            // alert(error)
            this.loading = false
          }
        )
    },
    getExceptions () {
      this.searching = true
      miscService.getExceptions()
        .then(
          r => {
            this.exceptions = r
            console.log('Exceptions >>', r)
            this.searching = false
          },
          error => {
            console.log(error)
            // alert(error)
            this.searching = false
          }
        )
    },
    getException (id) {
      this.loading = true
      miscService.getException(id)
        .then(
          r => {
            this.exception = r
            console.log('Exception >>', r)
            this.loading = false
          },
          error => {
            console.log(error)
            // alert(error)
            this.loading = false
          }
        )
    },
    getRoutines (id) {
      this.searching = true
      miscService.getRoutines(id)
        .then(
          r => {
            this.routines = r
            console.log('routines >>', r)
            this.searching = false
          },
          error => {
            console.log(error)
            // alert(error)
            this.searching = false
          }
        )
    },
    getDrivers (id) {
      this.searching = true
      miscService.getDrivers(id)
        .then(
          r => {
            this.drivers = r
            console.log('drivers >>', r)
            this.searching = false
          },
          error => {
            console.log(error)
            // alert(error)
            this.searching = false
          }
        )
    },
    getRegionCities (id) {
      this.searching = true
      miscService.getRegionCities(id)
        .then(
          r => {
            this.regionCities = r
            console.log('regionCities >>', r)
            this.searching = false
          },
          error => {
            console.log(error)
            // alert(error)
            this.searching = false
          }
        )
    },
    getAirportCities () {
      this.searching = true
      miscService.getAirportCities()
        .then(
          r => {
            this.airportCities = r
            console.log('regionCities >>', r)
            this.searching = false
          },
          error => {
            console.log(error)
            // alert(error)
            this.searching = false
          }
        )
    },
    getAllocations (id) {
      this.searching = true
      miscService.getAllocations(id)
        .then(
          r => {
            this.allocations = r
            console.log('allocations >>', r)
            this.searching = false
          },
          error => {
            console.log(error)
            // alert(error)
            this.searching = false
          }
        )
    },
    getUnverifiedDSS () {
      this.searching = true
      miscService.getUnverifiedDSS(this.subsidiaryId)
        .then(
          c => {
            this.dssList = c
            console.log(c)
            this.searching = false
          },
          e => {
            console.log(e)
            this.searching = false
          }
        )
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMiscStore, import.meta.hot))
}
