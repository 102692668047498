import { BehaviorSubject } from 'rxjs'

import { config } from '../config/index'
import requestOptions from '@/helpers/RequestOptions'
import handleResponse from '@/helpers/HandleResponses'

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')))

export const authenticationService = {
  login,
  logout,
  sendRecovery,
  changePassword,
  resetPassword,
  getUsers,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue () { return currentUserSubject.value }
}

async function login (email, password) {
  const response = await fetch(`${config.apiurl}/account/token`, requestOptions.post({ email, password }))
  const model = await handleResponse(response)
  // store user details and jwt token in local storage to keep user logged in between page refreshes
  localStorage.setItem('Token', model.token)
  if (model.user) {
    localStorage.setItem('currentUser', JSON.stringify({ user: model.user, token: model.token, other: model.other }))
    currentUserSubject.next({ user: model.user, token: model.token, other: model.other })
  }
  return model
}

async function sendRecovery (email) {
  const response = await fetch(`${config.apiurl}/Account/forgotPassword`, requestOptions.post({ email }))
  const id = await handleResponse(response)
  console.log(id)
  return id
}

async function getUsers () {
  const response = await fetch(`${config.apiurl}/Account/users`, requestOptions.get())
  const model = await handleResponse(response)
  console.log(model)
  return model
}

// async function changePassword (currentPassword, password, confirmPassword) {
//   console.log('change password 4')
//   const response = await fetch(`${config.apiurl}/Account/changePassword`, requestOptions.post({ currentPassword, password, confirmPassword }))
//   const id = await handleResponse(response)
//   console.log(id)
//   console.log('change password 5')
//   return id
// }

async function resetPassword (req) {
  const response = await fetch(`${config.apiurl}/Account/resetPassword`, requestOptions.post(req))
  const id = await handleResponse(response)
  console.log(id)
  console.log('change password 5')
  return id
}

async function changePassword (currentPassword, password) {
  const response = await fetch(`${config.apiurl}/Account/changePassword`, requestOptions.post({ currentPassword, password }))
  const id = await handleResponse(response)
  console.log(id)
  // console.log('change password 5')
  return id
}
async function logout () {
  // remove user from local  storage to log user out
  localStorage.removeItem('currentUser')

  currentUserSubject.next(null)
}
