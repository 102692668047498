<script setup>
import Navbar from '@/components/NavBar.vue'
import Sidebar from '@/components/SideBar.vue'
import BarChart from '@/components/BarChart.vue'
import Doughnut from '@/components/DoughnutChart.vue'
// import { saleService } from '@/services/SaleService'
import { useSaleStore } from '@/stores/SaleStore'
import { authenticationService } from '@/services/AuthenticationService'
import { storeToRefs } from 'pinia'
import { onMounted } from 'vue'

const saleStore = useSaleStore()
const {
  transSecOptions, transSecSeries, transCodeOptions, transCodeSeries, transFrontlineOptions, transFrontlineSeries, holdShipmentOptions, holdShipmentSeries, cusCodeOptions, cusCodeSeries, cusBirthOptions, cusBirthSeries,
  summary, recentOrders, highPoints
} = storeToRefs(saleStore)
const {
  getNoOfCustByCode, getNoOfTransByCode, getNoOfTransBySector, getCustByMonth, numberOfCusByCode, numberOfTransByCode, numberOfTransBySector, cusByMonth, frontlineSummary, marketerSummary,
  noOfSaleOrders, highPointCus, noOfShipsAtOrigin, noOfShipsAtDest, noOfShipsOnTransit, noOfDiscShipsByHubs, operatorSummary, noOfShipsAtRegOrigin, noOfShipsAtRegDest, noOfShipsOnRegTransit,
  managerSummary, downloadByOriginHubs, downloadByDestHubs, downloadByTransit, downloadByRegOriginHubs, downloadByRegDestHubs, downloadByRegTransit, downloadDiscByHubs,
  numberOfHoldShipmentByFrontline, numberOfTransByFrontline, getNoOfHoldShipByFrontline, getNoOfTransByFrontline
} = saleStore

const currentUser = authenticationService.currentUserValue.user
const role = currentUser.roles[0]
console.log('role', role)

onMounted(() => {
  if (role === 'Global Administrator' || role === 'Administrator') {
    marketerSummary()
    numberOfCusByCode()
    numberOfTransByCode()
    numberOfTransBySector()
    numberOfTransByFrontline()
    numberOfHoldShipmentByFrontline()
    cusByMonth()
    if (role === 'Administrator') {
      marketerSummary(saleStore.currentUser?.sbuUser?.sbuId)
    } else {
      marketerSummary(null)
    }
  }
  if (role === 'FrontLine') {
    frontlineSummary()
    noOfSaleOrders()
    highPointCus()
  }
  if (role === 'Operator' && saleStore.currentUser.sbuUser.sbuId !== 3) {
    noOfShipsAtOrigin(saleStore.currentFrontline.hub.cityId)
    noOfShipsAtDest(saleStore.currentFrontline.hub.cityId)
    noOfShipsOnTransit(saleStore.currentFrontline.hub.cityId)
    operatorSummary(saleStore.currentUser.sbuUser.sbuId)
    noOfDiscShipsByHubs(saleStore.currentFrontline.hub.cityId, 1)
  }
  if (role === 'Operator' && saleStore.currentUser.sbuUser.sbuId === 3) {
    noOfShipsAtOrigin(saleStore.currentFrontline.airportId)
    noOfShipsAtDest(saleStore.currentFrontline.airportId)
    noOfShipsOnTransit(saleStore.currentFrontline.airportId)
    operatorSummary(saleStore.currentUser.sbuUser.sbuId)
    noOfDiscShipsByHubs(saleStore.currentFrontline.airportId, 1)
  }
  if (role === 'Manager') {
    if (saleStore.currentFrontline.type === 1) {
      noOfShipsAtOrigin(saleStore.currentFrontline.area)
      noOfShipsAtDest(saleStore.currentFrontline.area)
      noOfShipsOnTransit(saleStore.currentFrontline.area)
      noOfDiscShipsByHubs(saleStore.currentFrontline.area, saleStore.currentFrontline.type)
    }
    if (saleStore.currentFrontline.type === 2) {
      noOfShipsAtRegOrigin()
      noOfShipsAtRegDest()
      noOfShipsOnRegTransit()
      noOfDiscShipsByHubs(saleStore.currentFrontline.area, saleStore.currentFrontline.type)
    }
    managerSummary()
  }
})
</script>

<script>

export default {
  data () {
    return {
      ordersHeaders: [
        {
          key: 'date',
          label: 'DATE'
        },
        {
          key: 'waybillNo',
          label: 'NUMBER'
        }
      ],
      pointHeaders: [
        {
          key: 'accountNo',
          label: 'Account Number'
        },
        {
          key: 'name',
          label: 'Full Name'
        },
        {
          key: 'centre',
          label: 'Centre Code'
        },
        {
          key: 'email',
          label: 'Email'
        },
        {
          key: 'points',
          label: 'Points'
        }
      ],
      sidebarVisible: false
    }
  },
  methods: {
    toggleSidebar (isVisible) {
      this.sidebarVisible = isVisible
      console.log('parentSide', this.sidebarVisible)
    }
  }
}
</script>

<template>
  <div class="">
    <Navbar></Navbar>
    <Sidebar />
    <div class="main-content">
      <div class="container-fluid py-4"  v-if="currentUser.roles[0] === 'Global Administrator' || currentUser.roles[0] === 'Administrator'">
        <div class="row mb-4">
          <div class="col-6 col-lg-3 mb-2">
            <div class="card header-pane-card">
              <div class="card-body">
                <div class="pane">
                  <p class="pane-title">EXPRESS CENTRES</p>
                  <p class="pane-text mb-0">{{ summary?.totalCentres ? summary?.totalCentres : 0 }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-lg-3 mb-2">
            <div class="card header-pane-card">
              <div class="card-body">
                <div class="pane">
                  <p class="pane-title">CUSTOMERS</p>
                  <p class="pane-text mb-0">{{ summary?.totalCustomers ? summary?.totalCustomers : 0 }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-lg-3 mb-2">
            <div class="card header-pane-card">
              <div class="card-body">
                <div class="pane">
                  <p class="pane-title">AVAILABLE DISCOUNTS</p>
                  <p class="pane-text mb-0">{{ summary?.totalDiscounts ? summary?.totalDiscounts : 0 }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-lg-3 mb-2">
            <div class="card header-pane-card">
              <div class="card-body">
                <div class="pane">
                  <p class="pane-title">TOTAL SALE ORDERS</p>
                  <p class="pane-text mb-0">{{ summary?.totalSaleOrder ? summary?.totalSaleOrder : 0 }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12 col-lg-6 mb-2">
            <div class="card stat-pane-card">
              <div class="card-header">
                <p class="header-title mb-0">
                  Number of Registered Customers by Express Code
                </p>
              </div>
              <div class="card-body py-0 px-0">
                <div class="body-pane-head px-3">
                  <div class="d-flex py-3">
                    <div class="form-input">
                      <div class="d-flex align-items-center py-1 w-100">
                        <div class="d-flex w-50">
                          <label for="startDate" class=" py-0 px-1 col-form-label pane-form-label">from</label>
                          <div class=" px-0 w-75">
                            <input type="date" class="form-control py-0 px-1 pane-form-input" id="startDate" v-model="saleStore.cusStartDate">
                          </div>
                        </div>
                        <div class="d-flex w-50">
                          <label for="endDate" class=" py-0 px-1 col-form-label pane-form-label">to</label>
                          <div class=" px-0 w-75">
                            <input type="date" class="form-control py-0 px-1 pane-form-input" id="endDate" v-model="saleStore.cusEndDate">
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr class="divide mx-2 my-0" />
                    <div class="d-flex">
                      <button class="btn btn-sm action-btn" @click="numberOfCusByCode()">
                        Search
                      </button>
                      <button v-if="cusCodeSeries.length > 0" class="btn btn-sm action-btn mx-2" @click="getNoOfCustByCode()">
                        Export
                      </button>
                    </div>
                  </div>
                </div>
                <div class="pane px-3 py-3">
                  <BarChart :options="cusCodeOptions && cusCodeOptions" :series="cusCodeSeries" />
                  <div class="text-center">
                    <p class="chart-title">Top Customers</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-2">
            <div class="card stat-pane-card">
              <div class="card-header">
                <p class="header-title mb-0">
                  Number of Transactions by Express Code
                </p>
              </div>
              <div class="card-body py-0 px-0">
                <div class="body-pane-head px-3">
                  <div class="d-flex py-3">
                    <div class="form-input">
                      <div class="d-flex align-items-center py-1 w-100">
                        <div class="d-flex w-50">
                          <label for="startDate" class=" py-0 px-1 col-form-label pane-form-label">from</label>
                          <div class=" px-0 w-75">
                            <input type="date" class="form-control py-0 px-1 pane-form-input" id="startDate" v-model="saleStore.transStartDate">
                          </div>
                        </div>
                        <div class="d-flex w-50">
                          <label for="endDate" class=" py-0 px-1 col-form-label pane-form-label">to</label>
                          <div class=" px-0 w-75">
                            <input type="date" class="form-control py-0 px-1 pane-form-input" id="endDate" v-model="saleStore.transEndDate">
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr class="divide mx-2 my-0" />
                    <div class="d-flex">
                      <button class="btn btn-sm action-btn" @click="numberOfTransByCode()">
                        Search
                      </button>
                      <button v-if="transCodeSeries.length > 0" class="btn btn-sm action-btn mx-2" @click="getNoOfTransByCode()">
                        Export
                      </button>
                    </div>
                  </div>
                </div>
                <div class="pane px-3 py-3">
                  <BarChart :options="transCodeOptions && transCodeOptions" :series="transCodeSeries" />
                  <div class="text-center">
                    <p class="chart-title">Top Transactions</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-2">
            <div class="card stat-pane-card">
              <div class="card-header">
                <p class="header-title mb-0">
                  Number of Transactions by Frontline
                </p>
              </div>
              <div class="card-body py-0 px-0">
                <div class="body-pane-head px-3">
                  <div class="d-flex py-3">
                    <div class="form-input">
                      <div class="d-flex align-items-center py-1 w-100">
                        <div class="d-flex w-50">
                          <label for="startDate" class=" py-0 px-1 col-form-label pane-form-label">from</label>
                          <div class=" px-0 w-75">
                            <input type="date" class="form-control py-0 px-1 pane-form-input" id="startDate" v-model="saleStore.cusStartDate">
                          </div>
                        </div>
                        <div class="d-flex w-50">
                          <label for="endDate" class=" py-0 px-1 col-form-label pane-form-label">to</label>
                          <div class=" px-0 w-75">
                            <input type="date" class="form-control py-0 px-1 pane-form-input" id="endDate" v-model="saleStore.cusEndDate">
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr class="divide mx-2 my-0" />
                    <div class="d-flex">
                      <button class="btn btn-sm action-btn" @click="numberOfTransByFrontline()">
                        Search
                      </button>
                      <button v-if="transFrontlineSeries.length > 0" class="btn btn-sm action-btn mx-2" @click="getNoOfTransByFrontline()">
                        Export
                      </button>
                    </div>
                  </div>
                </div>
                <div class="pane px-3 py-3">
                  <BarChart :options="transFrontlineOptions && transFrontlineOptions" :series="transFrontlineSeries" />
                  <div class="text-center">
                    <p class="chart-title">Top Transactions</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-2">
            <div class="card stat-pane-card">
              <div class="card-header">
                <p class="header-title mb-0">
                  Number of Holding Shipment by Frontline (Daily)
                </p>
              </div>
              <div class="card-body py-0 px-0">
                <div class="body-pane-head px-3">
                  <div class="d-flex py-3">
                    <hr class="divide mx-2 my-0" />
                    <div class="d-flex">
                      <button v-if="holdShipmentSeries.length > 0" class="btn btn-sm action-btn mx-2" @click="getNoOfHoldShipByFrontline()">
                        Export
                      </button>
                    </div>
                  </div>
                </div>
                <div class="pane px-3 py-3">
                  <BarChart :options="holdShipmentOptions && holdShipmentOptions" :series="holdShipmentSeries" />
                  <div class="text-center">
                    <p class="chart-title">Holding Shipment</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-2">
            <div class="card stat-pane-card">
              <div class="card-header">
                <p class="header-title mb-0">
                  Number of Transactions by Sector
                </p>
              </div>
              <div class="card-body py-0 px-0">
                <div class="body-pane-head px-3">
                  <div class="d-flex py-3">
                    <div class="form-input">
                      <div class="d-flex align-items-center py-1 w-100">
                        <div class="d-flex w-50">
                          <label for="startDate" class=" py-0 px-1 col-form-label pane-form-label">from</label>
                          <div class=" px-0 w-75">
                            <input type="date" class="form-control py-0 px-1 pane-form-input" id="startDate" v-model="saleStore.sectorStartDate">
                          </div>
                        </div>
                        <div class="d-flex w-50">
                          <label for="endDate" class=" py-0 px-1 col-form-label pane-form-label">to</label>
                          <div class=" px-0 w-75">
                            <input type="date" class="form-control py-0 px-1 pane-form-input" id="endDate" v-model="saleStore.sectorEndDate">
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr class="divide mx-2 my-0" />
                    <div class="d-flex">
                      <button class="btn btn-sm action-btn" @click="numberOfTransBySector()">
                        Search
                      </button>
                      <button v-if="transSecSeries.length > 0" class="btn btn-sm action-btn mx-2" @click="getNoOfTransBySector()">
                        Export
                      </button>
                    </div>
                  </div>
                </div>
                <div class="pane px-3 py-3">
                  <BarChart :options="transSecOptions && transSecOptions" :series="transSecSeries" />
                  <div class="text-center">
                    <p class="chart-title">Top Sectors</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-2">
            <div class="card stat-pane-card">
              <div class="card-header">
                <p class="header-title mb-0">
                  Number of Customers by Birth Month
                </p>
              </div>
              <div class="card-body py-0 px-0">
                <div class="body-pane-head px-3">
                  <div class="d-flex py-3">
                    <div class="ms-auto">
                      <button v-if="cusBirthSeries.length > 0" class="btn btn-sm action-btn mx-2" @click="getCustByMonth()">
                        Export
                      </button>
                    </div>
                  </div>
                </div>
                <div class="pane px-3 py-3">
                  <Doughnut :options="cusBirthOptions && cusBirthOptions" :series="cusBirthSeries" />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="container-fluid py-4" v-if="currentUser.roles[0] === 'FrontLine'">
        <div class="row mb-4">
          <div class="col-12 col-sm-4 mb-2">
            <div class="card header-pane-card">
              <div class="card-body">
                <div class="pane">
                  <p class="pane-title">Customers</p>
                  <p class="pane-text mb-0">{{ summary?.totalCustomers ? summary?.totalCustomers : 0 }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-4 mb-2">
            <div class="card header-pane-card">
              <div class="card-body">
                <div class="pane">
                  <p class="pane-title">Available Discounts</p>
                  <p class="pane-text mb-0">{{ summary?.totalDiscounts ? summary?.totalDiscounts : 0 }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-4 mb-2">
            <div class="card header-pane-card">
              <div class="card-body">
                <div class="pane">
                  <p class="pane-title">Total Sale Orders</p>
                  <p class="pane-text mb-0">{{ summary?.totalSaleOrder ? summary?.totalSaleOrder: 0 }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12 col-lg-6">
            <div class="card stat-pane-card">
              <div class="card-header">
                <p class="header-title mb-0">
                  Recent Orders
                </p>
              </div>
              <div class="card-body py-0 px-0">
                <div class="">
                  <b-table
                    class="elevation-1 frontline-table"
                    id="staffTable"
                    responsive
                    outlined
                    :fields="ordersHeaders"
                    :items="recentOrders"
                  >
                    <template #cell(date)="data">
                      <div class="">
                      {{ data.item.shipment.createdOn ? new Date(data.item.shipment.createdOn).toLocaleDateString() : null }}
                      </div>
                    </template>
                    <template #cell(waybillNo)="data">
                      <div class="">
                      {{ data.item.shipment.waybillNumber }}
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="card stat-pane-card">
              <div class="card-header">
                <p class="header-title mb-0">
                  Highest Point Customers
                </p>
              </div>
              <div class="card-body py-0 px-0">
                <div class="">
                  <b-table
                    class="elevation-1 frontline-table"
                    id="staffTable"
                    responsive
                    outlined
                    :fields="pointHeaders"
                    :items="highPoints"
                  >
                    <template #cell(accountNo)="data">
                      <div class="">
                      {{ data.item.accountNo }}
                      </div>
                    </template>
                    <template #cell(name)="data">
                      <div class="">
                      {{ data.item.firstName + ' ' + data.item.lastName }}
                      </div>
                    </template>
                    <template #cell(centre)="data">
                      <div class="">
                      {{ data.item.expressCentreCode }}
                      </div>
                    </template>
                    <template #cell(email)="data">
                      <div class="">
                      {{ data.item.email ? data.item.email : 'N/A' }}
                      </div>
                    </template>
                    <template #cell(points)="data">
                      <div class="">
                      {{ data.item.rewardPoint.point ? data.item.rewardPoint.point.toFixed(3) : 0 }}
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid py-4"  v-if="currentUser.roles[0] === 'Operator' && currentUser.sbuUser.sbuId !== 3">
        <div class="row mb-4">
          <div class="col-12 col-sm-4 mb-2">
            <div class="card header-pane-card">
              <div class="card-body">
                <div class="pane">
                  <p class="pane-title">Total Arrived Shipments</p>
                  <p class="pane-text mb-0">{{ summary.totalArrivedShipments }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-4 mb-2">
            <div class="card header-pane-card">
              <div class="card-body">
                <div class="pane">
                  <p class="pane-title">Total Processed Shipments</p>
                  <p class="pane-text mb-0">{{ summary.totalProcessedShipments }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-4 mb-2">
            <div class="card header-pane-card">
              <div class="card-body">
                <div class="pane">
                  <p class="pane-title">Total Delayed Shipment</p>
                  <p class="pane-text mb-0">{{ summary.totalDelayedShipments }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12 col-lg-6 mb-2">
            <div class="card stat-pane-card">
              <div class="card-header">
                <p class="header-title mb-0">
                  Shipment at Origin Hubs
                </p>
              </div>
              <div class="card-body py-0 px-0">
                <div class="body-pane-head px-3">
                  <div class="d-flex py-3">
                    <div class="form-input">
                      <div class="d-flex align-items-center py-1 w-100">
                        <div class="d-flex w-50">
                          <label for="startDate" class=" py-0 px-1 col-form-label pane-form-label">from</label>
                          <div class=" px-0 w-75">
                            <input type="date" class="form-control py-0 px-1 pane-form-input" id="startDate" v-model="saleStore.cusStartDate">
                          </div>
                        </div>
                        <div class="d-flex w-50">
                          <label for="endDate" class=" py-0 px-1 col-form-label pane-form-label">to</label>
                          <div class=" px-0 w-75">
                            <input type="date" class="form-control py-0 px-1 pane-form-input" id="endDate" v-model="saleStore.cusEndDate">
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr class="divide mx-2 my-0" />
                    <div class="d-flex">
                      <button class="btn btn-sm action-btn" @click="noOfShipsAtOrigin(saleStore.currentFrontline.hub.cityId)">
                        Search
                      </button>
                      <button v-if="cusCodeSeries.length > 0" class="btn btn-sm action-btn mx-2" @click="downloadByOriginHubs(saleStore.currentFrontline.hub.cityId)">
                        Export
                      </button>
                    </div>
                  </div>
                </div>
                <div class="pane px-3 py-3">
                  <BarChart :options="cusCodeOptions && cusCodeOptions" :series="cusCodeSeries" />
                  <div class="text-center">
                    <p class="chart-title">Status at Origin Hubs</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-2">
            <div class="card stat-pane-card">
              <div class="card-header">
                <p class="header-title mb-0">
                  Shipment at Destination Hubs
                </p>
              </div>
              <div class="card-body py-0 px-0">
                <div class="body-pane-head px-3">
                  <div class="d-flex py-3">
                    <div class="form-input">
                      <div class="d-flex align-items-center py-1 w-100">
                        <div class="d-flex w-50">
                          <label for="startDate" class=" py-0 px-1 col-form-label pane-form-label">from</label>
                          <div class=" px-0 w-75">
                            <input type="date" class="form-control py-0 px-1 pane-form-input" id="startDate" v-model="saleStore.transStartDate">
                          </div>
                        </div>
                        <div class="d-flex w-50">
                          <label for="endDate" class=" py-0 px-1 col-form-label pane-form-label">to</label>
                          <div class=" px-0 w-75">
                            <input type="date" class="form-control py-0 px-1 pane-form-input" id="endDate" v-model="saleStore.transEndDate">
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr class="divide mx-2 my-0" />
                    <div class="d-flex">
                      <button class="btn btn-sm action-btn" @click="noOfShipsAtDest(saleStore.currentFrontline.hub.cityId)">
                        Search
                      </button>
                      <button v-if="transCodeSeries.length > 0" class="btn btn-sm action-btn mx-2" @click="downloadByDestHubs(saleStore.currentFrontline.hub.cityId)">
                        Export
                      </button>
                    </div>
                  </div>
                </div>
                <div class="pane px-3 py-3">
                  <BarChart :options="transCodeOptions && transCodeOptions" :series="transCodeSeries" />
                  <div class="text-center">
                    <p class="chart-title">Status at Destination Hubs</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-2">
            <div class="card stat-pane-card">
              <div class="card-header">
                <p class="header-title mb-0">
                  Shipment on Transit
                </p>
              </div>
              <div class="card-body py-0 px-0">
                <div class="body-pane-head px-3">
                  <div class="d-flex py-3">
                    <div class="form-input">
                      <div class="d-flex align-items-center py-1 w-100">
                        <div class="d-flex w-50">
                          <label for="startDate" class=" py-0 px-1 col-form-label pane-form-label">from</label>
                          <div class=" px-0 w-75">
                            <input type="date" class="form-control py-0 px-1 pane-form-input" id="startDate" v-model="saleStore.sectorStartDate">
                          </div>
                        </div>
                        <div class="d-flex w-50">
                          <label for="endDate" class=" py-0 px-1 col-form-label pane-form-label">to</label>
                          <div class=" px-0 w-75">
                            <input type="date" class="form-control py-0 px-1 pane-form-input" id="endDate" v-model="saleStore.sectorEndDate">
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr class="divide mx-2 my-0" />
                    <div class="d-flex">
                      <button class="btn btn-sm action-btn" @click="noOfShipsOnTransit(saleStore.currentFrontline.hub.cityId)">
                        Search
                      </button>
                      <button v-if="transSecSeries.length > 0" class="btn btn-sm action-btn mx-2" @click="downloadByTransit(saleStore.currentFrontline.hub.cityId)">
                        Export
                      </button>
                    </div>
                  </div>
                </div>
                <div class="pane px-3 py-3">
                  <BarChart :options="transSecOptions && transSecOptions" :series="transSecSeries" />
                  <div class="text-center">
                    <p class="chart-title">Status on Transit</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-2">
            <div class="card stat-pane-card">
              <div class="card-header">
                <p class="header-title mb-0">
                  Discrepancy Shipment by Hubs
                </p>
              </div>
              <div class="card-body py-0 px-0">
                <div class="body-pane-head px-3">
                  <div class="d-flex py-3">
                    <div class="ms-auto">
                      <button v-if="cusBirthSeries.length > 0" class="btn btn-sm action-btn mx-2" @click="downloadDiscByHubs(saleStore.currentFrontline.hub.cityId, 1)">
                        Export
                      </button>
                    </div>
                  </div>
                </div>
                <div class="pane px-3 py-3">
                  <Doughnut :options="cusBirthOptions && cusBirthOptions" :series="cusBirthSeries" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid py-4"  v-if="currentUser.roles[0] === 'Operator' && currentUser.sbuUser.sbuId === 3">
        <div class="row mb-4">
          <div class="col-12 col-sm-4 mb-2">
            <div class="card header-pane-card">
              <div class="card-body">
                <div class="pane">
                  <p class="pane-title">Total Arrived Shipments</p>
                  <p class="pane-text mb-0">{{ summary.totalArrivedShipments }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-4 mb-2">
            <div class="card header-pane-card">
              <div class="card-body">
                <div class="pane">
                  <p class="pane-title">Total Processed Shipments</p>
                  <p class="pane-text mb-0">{{ summary.totalProcessedShipments }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-4 mb-2">
            <div class="card header-pane-card">
              <div class="card-body">
                <div class="pane">
                  <p class="pane-title">Total Delayed Shipment</p>
                  <p class="pane-text mb-0">{{ summary.totalDelayedShipments }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12 col-lg-6 mb-2">
            <div class="card stat-pane-card">
              <div class="card-header">
                <p class="header-title mb-0">
                  Shipment at Origin Hubs
                </p>
              </div>
              <div class="card-body py-0 px-0">
                <div class="body-pane-head px-3">
                  <div class="d-flex py-3">
                    <div class="form-input">
                      <div class="d-flex align-items-center py-1 w-100">
                        <div class="d-flex w-50">
                          <label for="startDate" class=" py-0 px-1 col-form-label pane-form-label">from</label>
                          <div class=" px-0 w-75">
                            <input type="date" class="form-control py-0 px-1 pane-form-input" id="startDate" v-model="saleStore.cusStartDate">
                          </div>
                        </div>
                        <div class="d-flex w-50">
                          <label for="endDate" class=" py-0 px-1 col-form-label pane-form-label">to</label>
                          <div class=" px-0 w-75">
                            <input type="date" class="form-control py-0 px-1 pane-form-input" id="endDate" v-model="saleStore.cusEndDate">
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr class="divide mx-2 my-0" />
                    <div class="d-flex">
                      <button class="btn btn-sm action-btn" @click="noOfShipsAtOrigin(saleStore.currentFrontline.airportId)">
                        Search
                      </button>
                      <button v-if="cusCodeSeries.length > 0" class="btn btn-sm action-btn mx-2" @click="downloadByOriginHubs(saleStore.currentFrontline.airportId)">
                        Export
                      </button>
                    </div>
                  </div>
                </div>
                <div class="pane px-3 py-3">
                  <BarChart :options="cusCodeOptions && cusCodeOptions" :series="cusCodeSeries" />
                  <div class="text-center">
                    <p class="chart-title">Status at Origin Hubs</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-2">
            <div class="card stat-pane-card">
              <div class="card-header">
                <p class="header-title mb-0">
                  Shipment at Destination Hubs
                </p>
              </div>
              <div class="card-body py-0 px-0">
                <div class="body-pane-head px-3">
                  <div class="d-flex py-3">
                    <div class="form-input">
                      <div class="d-flex align-items-center py-1 w-100">
                        <div class="d-flex w-50">
                          <label for="startDate" class=" py-0 px-1 col-form-label pane-form-label">from</label>
                          <div class=" px-0 w-75">
                            <input type="date" class="form-control py-0 px-1 pane-form-input" id="startDate" v-model="saleStore.transStartDate">
                          </div>
                        </div>
                        <div class="d-flex w-50">
                          <label for="endDate" class=" py-0 px-1 col-form-label pane-form-label">to</label>
                          <div class=" px-0 w-75">
                            <input type="date" class="form-control py-0 px-1 pane-form-input" id="endDate" v-model="saleStore.transEndDate">
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr class="divide mx-2 my-0" />
                    <div class="d-flex">
                      <button class="btn btn-sm action-btn" @click="noOfShipsAtDest(saleStore.currentFrontline.airportId)">
                        Search
                      </button>
                      <button v-if="transCodeSeries.length > 0" class="btn btn-sm action-btn mx-2" @click="downloadByDestHubs(saleStore.currentFrontline.airportId)">
                        Export
                      </button>
                    </div>
                  </div>
                </div>
                <div class="pane px-3 py-3">
                  <BarChart :options="transCodeOptions && transCodeOptions" :series="transCodeSeries" />
                  <div class="text-center">
                    <p class="chart-title">Status at Destination Hubs</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-2">
            <div class="card stat-pane-card">
              <div class="card-header">
                <p class="header-title mb-0">
                  Shipment on Transit
                </p>
              </div>
              <div class="card-body py-0 px-0">
                <div class="body-pane-head px-3">
                  <div class="d-flex py-3">
                    <div class="form-input">
                      <div class="d-flex align-items-center py-1 w-100">
                        <div class="d-flex w-50">
                          <label for="startDate" class=" py-0 px-1 col-form-label pane-form-label">from</label>
                          <div class=" px-0 w-75">
                            <input type="date" class="form-control py-0 px-1 pane-form-input" id="startDate" v-model="saleStore.sectorStartDate">
                          </div>
                        </div>
                        <div class="d-flex w-50">
                          <label for="endDate" class=" py-0 px-1 col-form-label pane-form-label">to</label>
                          <div class=" px-0 w-75">
                            <input type="date" class="form-control py-0 px-1 pane-form-input" id="endDate" v-model="saleStore.sectorEndDate">
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr class="divide mx-2 my-0" />
                    <div class="d-flex">
                      <button class="btn btn-sm action-btn" @click="noOfShipsOnTransit(saleStore.currentFrontline.airportId)">
                        Search
                      </button>
                      <button v-if="transSecSeries.length > 0" class="btn btn-sm action-btn mx-2" @click="downloadByTransit(saleStore.currentFrontline.airportId)">
                        Export
                      </button>
                    </div>
                  </div>
                </div>
                <div class="pane px-3 py-3">
                  <BarChart :options="transSecOptions && transSecOptions" :series="transSecSeries" />
                  <div class="text-center">
                    <p class="chart-title">Status on Transit</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-2">
            <div class="card stat-pane-card">
              <div class="card-header">
                <p class="header-title mb-0">
                  Discrepancy Shipment by Hubs
                </p>
              </div>
              <div class="card-body py-0 px-0">
                <div class="body-pane-head px-3">
                  <div class="d-flex py-3">
                    <div class="ms-auto">
                      <button v-if="cusBirthSeries.length > 0" class="btn btn-sm action-btn mx-2" @click="downloadDiscByHubs(saleStore.currentFrontline.airportId, 1)">
                        Export
                      </button>
                    </div>
                  </div>
                </div>
                <div class="pane px-3 py-3">
                  <Doughnut :options="cusBirthOptions && cusBirthOptions" :series="cusBirthSeries" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid py-4"  v-if="currentUser.roles[0] === 'Manager'">
        <div class="row mb-4">
          <div class="col-12 col-sm-4 mb-2">
            <div class="card header-pane-card">
              <div class="card-body">
                <div class="pane">
                  <p class="pane-title">Total Arrived Shipments</p>
                  <p class="pane-text mb-0">{{ summary.totalArrivedShipments }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-4 mb-2">
            <div class="card header-pane-card">
              <div class="card-body">
                <div class="pane">
                  <p class="pane-title">Total Processed Shipments</p>
                  <p class="pane-text mb-0">{{ summary.totalProcessedShipments }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-4 mb-2">
            <div class="card header-pane-card">
              <div class="card-body">
                <div class="pane">
                  <p class="pane-title">Total Delayed Shipments</p>
                  <p class="pane-text mb-0">{{ summary.totalDelayedShipments }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12 col-lg-6 mb-2">
            <div class="card stat-pane-card">
              <div class="card-header">
                <p class="header-title mb-0" v-if="saleStore.currentFrontline?.type === 2">
                  Shipment at Regional Origin Hubs
                </p>
                <p class="header-title mb-0" v-if="saleStore.currentFrontline?.type === 1">
                  Shipment at Origin Hubs
                </p>
              </div>
              <div class="card-body py-0 px-0">
                <div class="body-pane-head px-3">
                  <div class="d-flex py-3">
                    <div class="form-input">
                      <div class="d-flex align-items-center py-1 w-100">
                        <div class="d-flex w-50">
                          <label for="startDate" class=" py-0 px-1 col-form-label pane-form-label">from</label>
                          <div class=" px-0 w-75">
                            <input type="date" class="form-control py-0 px-1 pane-form-input" id="startDate" v-model="saleStore.cusStartDate">
                          </div>
                        </div>
                        <div class="d-flex w-50">
                          <label for="endDate" class=" py-0 px-1 col-form-label pane-form-label">to</label>
                          <div class=" px-0 w-75">
                            <input type="date" class="form-control py-0 px-1 pane-form-input" id="endDate" v-model="saleStore.cusEndDate">
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr class="divide mx-2 my-0" />
                    <div class="d-flex">
                      <button class="btn btn-sm action-btn" @click="saleStore.currentFrontline?.type === 1 ? noOfShipsAtOrigin(saleStore.currentFrontline.area) : noOfShipsAtRegOrigin()">
                        Search
                      </button>
                      <button v-if="cusCodeSeries.length > 0" class="btn btn-sm action-btn mx-2" @click="saleStore.currentFrontline?.type === 1 ? downloadByOriginHubs(saleStore.currentFrontline.area) : downloadByRegOriginHubs()">
                        Export
                      </button>
                    </div>
                  </div>
                </div>
                <div class="pane px-3 py-3">
                  <BarChart :options="cusCodeOptions && cusCodeOptions" :series="cusCodeSeries" />
                  <div class="text-center">
                    <p class="chart-title" v-if="saleStore.currentFrontline?.type === 1">Status at Origin Hubs</p>
                    <p class="chart-title" v-if="saleStore.currentFrontline?.type === 2">Status at Region Origin Hubs</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-2">
            <div class="card stat-pane-card">
              <div class="card-header">
                <p class="header-title mb-0" v-if="saleStore.currentFrontline?.type === 1">
                  Shipment at Destination Hubs
                </p>
                <p class="header-title mb-0" v-if="saleStore.currentFrontline?.type === 2">
                  Shipment at Regional Destination Hubs
                </p>
              </div>
              <div class="card-body py-0 px-0">
                <div class="body-pane-head px-3">
                  <div class="d-flex py-3">
                    <div class="form-input">
                      <div class="d-flex align-items-center py-1 w-100">
                        <div class="d-flex w-50">
                          <label for="startDate" class=" py-0 px-1 col-form-label pane-form-label">from</label>
                          <div class=" px-0 w-75">
                            <input type="date" class="form-control py-0 px-1 pane-form-input" id="startDate" v-model="saleStore.transStartDate">
                          </div>
                        </div>
                        <div class="d-flex w-50">
                          <label for="endDate" class=" py-0 px-1 col-form-label pane-form-label">to</label>
                          <div class=" px-0 w-75">
                            <input type="date" class="form-control py-0 px-1 pane-form-input" id="endDate" v-model="saleStore.transEndDate">
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr class="divide mx-2 my-0" />
                    <div class="d-flex">
                      <button class="btn btn-sm action-btn" @click="saleStore.currentFrontline?.type === 1 ? noOfShipsAtDest(saleStore.currentFrontline.area) : noOfShipsAtRegDest()">
                        Search
                      </button>
                      <button v-if="transCodeSeries.length > 0" class="btn btn-sm action-btn mx-2" @click="saleStore.currentFrontline?.type === 1 ? downloadByDestHubs(saleStore.currentFrontline.area) : downloadByRegDestHubs()">
                        Export
                      </button>
                    </div>
                  </div>
                </div>
                <div class="pane px-3 py-3">
                  <BarChart :options="transCodeOptions && transCodeOptions" :series="transCodeSeries" />
                  <div class="text-center">
                    <p class="chart-title" v-if="saleStore.currentFrontline?.type === 1">Status at Destination Hubs</p>
                    <p class="chart-title" v-if="saleStore.currentFrontline?.type === 2">Status at Region Destination Hubs</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-2">
            <div class="card stat-pane-card">
              <div class="card-header">
                <p class="header-title mb-0" v-if="saleStore.currentFrontline?.type === 1">
                  Shipment on Transit
                </p>
                <p class="header-title mb-0" v-if="saleStore.currentFrontline?.type === 2">
                  Shipment on Transit By Region
                </p>
              </div>
              <div class="card-body py-0 px-0">
                <div class="body-pane-head px-3">
                  <div class="d-flex py-3">
                    <div class="form-input">
                      <div class="d-flex align-items-center py-1 w-100">
                        <div class="d-flex w-50">
                          <label for="startDate" class=" py-0 px-1 col-form-label pane-form-label">from</label>
                          <div class=" px-0 w-75">
                            <input type="date" class="form-control py-0 px-1 pane-form-input" id="startDate" v-model="saleStore.sectorStartDate">
                          </div>
                        </div>
                        <div class="d-flex w-50">
                          <label for="endDate" class=" py-0 px-1 col-form-label pane-form-label">to</label>
                          <div class=" px-0 w-75">
                            <input type="date" class="form-control py-0 px-1 pane-form-input" id="endDate" v-model="saleStore.sectorEndDate">
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr class="divide mx-2 my-0" />
                    <div class="d-flex">
                      <button class="btn btn-sm action-btn" @click="saleStore.currentFrontline?.type === 1 ? noOfShipsOnTransit(saleStore.currentFrontline.area) : noOfShipsOnRegTransit()">
                        Search
                      </button>
                      <button v-if="transSecSeries.length > 0" class="btn btn-sm action-btn mx-2" @click="saleStore.currentFrontline?.type === 1 ? downloadByTransit(saleStore.currentFrontline.area) : downloadByRegTransit()">
                        Export
                      </button>
                    </div>
                  </div>
                </div>
                <div class="pane px-3 py-3">
                  <BarChart :options="transSecOptions && transSecOptions" :series="transSecSeries" />
                  <div class="text-center">
                    <p class="chart-title">Status on Transit</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-2">
            <div class="card stat-pane-card">
              <div class="card-header">
                <p class="header-title mb-0" v-if="saleStore.currentFrontline?.type === 1">
                  Discrepancy Shipment by Hubs
                </p>
                <p class="header-title mb-0" v-if="saleStore.currentFrontline?.type === 2">
                  Discrepancy Shipment by Region Hubs
                </p>
              </div>
              <div class="card-body py-0 px-0">
                <div class="body-pane-head px-3">
                  <div class="d-flex py-3">
                    <div class="ms-auto">
                      <button v-if="cusBirthSeries.length > 0" class="btn btn-sm action-btn mx-2" @click="downloadDiscByHubs(saleStore.currentFrontline.area, saleStore.currentFrontline.type)">
                        Export
                      </button>
                    </div>
                  </div>
                </div>
                <div class="pane px-3 py-3">
                  <Doughnut :options="cusBirthOptions && cusBirthOptions" :series="cusBirthSeries" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
