import { config } from '../config/index'
import requestOptions from '@/helpers/RequestOptions'
import handleResponse from '@/helpers/HandleResponses'
// import handleBlobResponse from '@/helpers/HandleBlobResponses'
// import handleTextResponse from '@/helpers/HandleTextResponses'

export const expenseService = {
  isManager,
  getOperationManagers,
  getOperationManager,
  createOperationManager,
  deleteOperationManager,
  getExpenseClaims,
  getExpenseClaim,
  getExpenseClaimsByUnit,
  getExpenseClaimsByEmployee,
  getExpenseClaimsByTeam,
  getExpenseClaimItems,
  getExpenseType,
  createExpenseClaim,
  createExpenseClaimItem,
  createExpenseType,
  approveExpenseClaim,
  updateExpenseClaim,
  updateExpenseClaimItem,
  updateExpenseType,
  deleteExpenseClaim,
  deleteExpenseClaimItem,
  deleteExpenseType,
  getCashAdvances,
  getCashAdvance,
  getCashAdvancesByUnit,
  getCashAdvancesByEmployee,
  getCashAdvancesByTeam,
  createCashAdvance,
  approveCashAdvance,
  updateCashAdvance,
  deleteCashAdvance,
  getCashRetirementByUnit,
  getCashRetirementByTeam,
  getCashRetirementLine,
  getCashRetirementLink,
  createCashRetirement,
  createCashRetirementLine,
  createCashRetirementLink,
  updateCashRetirement,
  updateCashRetirementLine,
  updateCashRetirementLink,
  deleteCashRetirement,
  deleteCashRetirementLine,
  deleteCashRetirementLink
}

async function isManager (id) {
  const response = await fetch(`${config.apiurl}/Expense/IsManager/${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getOperationManagers () {
  const response = await fetch(`${config.apiurl}/Expense/GetOperationalManagers`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getOperationManager (id) {
  const response = await fetch(`${config.apiurl}/Expense/GetOperationalManager/${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createOperationManager (data) {
  const response = await fetch(`${config.apiurl}/Expense/PostOperationalEmployeeManager`, requestOptions.post(data))
  const model = await handleResponse(response)
  return model
}

async function deleteOperationManager (id) {
  const response = await fetch(`${config.apiurl}/Expense/deleteOperationalEmployeeManager/${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function getExpenseClaims (id) {
  const response = await fetch(`${config.apiurl}/Expense/GetExpenseClaims/${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getExpenseClaim (id) {
  const response = await fetch(`${config.apiurl}/Expense/GetExpenseClaimById/${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getExpenseClaimsByUnit (sbuid) {
  const response = await fetch(`${config.apiurl}/Expense/GetExpenseClaimByUnit/${sbuid}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getExpenseClaimsByEmployee (userId) {
  const response = await fetch(`${config.apiurl}/Expense/GetExpenseClaimByEmployee/${userId}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getExpenseClaimsByTeam (managerId) {
  const response = await fetch(`${config.apiurl}/Expense/GetExpenseClaimByTeam/${managerId}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getExpenseClaimItems () {
  const response = await fetch(`${config.apiurl}/Expense/GetAllExpenseClaimItem`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getExpenseType (id) {
  const response = await fetch(`${config.apiurl}/Expense/GetExpenseType/${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createExpenseClaim (data) {
  const response = await fetch(`${config.apiurl}/Expense/PostExpenseClaim`, requestOptions.post(data))
  const model = await handleResponse(response)
  return model
}

async function createExpenseClaimItem (data) {
  const response = await fetch(`${config.apiurl}/Expense/PostExpenseClaimItem`, requestOptions.post(data))
  const model = await handleResponse(response)
  return model
}

async function createExpenseType (data) {
  const response = await fetch(`${config.apiurl}/Expense/PostExpenseType`, requestOptions.post(data))
  const model = await handleResponse(response)
  return model
}

async function approveExpenseClaim (data) {
  const response = await fetch(`${config.apiurl}/Expense/ApproveExpenseClaim`, requestOptions.post(data))
  const model = await handleResponse(response)
  return model
}

async function updateExpenseClaim (data) {
  const response = await fetch(`${config.apiurl}/Expense/PutExpenseClaim`, requestOptions.put(data))
  const model = await handleResponse(response)
  return model
}

async function updateExpenseClaimItem (data) {
  const response = await fetch(`${config.apiurl}/Expense/PutExpenseClaimItem`, requestOptions.putForm(data))
  const model = await handleResponse(response)
  return model
}

async function updateExpenseType (data) {
  const response = await fetch(`${config.apiurl}/Expense/PutExpenseType`, requestOptions.put(data))
  const model = await handleResponse(response)
  return model
}

async function deleteExpenseClaim (id) {
  const response = await fetch(`${config.apiurl}/Expense/deleteExpenseClaim/${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function deleteExpenseClaimItem (id) {
  const response = await fetch(`${config.apiurl}/Expense/deleteExpenseClaimItem?Id=${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function deleteExpenseType (id) {
  const response = await fetch(`${config.apiurl}/Expense/deleteExpenseType/${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function getCashAdvances (id) {
  const response = await fetch(`${config.apiurl}/Expense/GetCashAdvances/${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getCashAdvance (id) {
  const response = await fetch(`${config.apiurl}/Expense/GetCashAdvanceById/${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getCashAdvancesByUnit (sbuid) {
  const response = await fetch(`${config.apiurl}/Expense/GetCashAdvancemByUnit/${sbuid}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getCashAdvancesByEmployee (userId) {
  const response = await fetch(`${config.apiurl}/Expense/GetCashAdvanceByEmployee/${userId}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getCashAdvancesByTeam (managerId) {
  const response = await fetch(`${config.apiurl}/Expense/GetCashAdvanceByTeam/${managerId}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createCashAdvance (data) {
  const response = await fetch(`${config.apiurl}/Expense/PostCashAdvance`, requestOptions.post(data))
  const model = await handleResponse(response)
  return model
}

async function approveCashAdvance (data) {
  const response = await fetch(`${config.apiurl}/Expense/ApproveCashAdvance`, requestOptions.post(data))
  const model = await handleResponse(response)
  return model
}

async function updateCashAdvance (data) {
  const response = await fetch(`${config.apiurl}/Expense/PutCashAdvance`, requestOptions.put(data))
  const model = await handleResponse(response)
  return model
}

async function deleteCashAdvance (id) {
  const response = await fetch(`${config.apiurl}/Expense/deleteCashAdvance/${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function getCashRetirementByUnit (sbuid) {
  const response = await fetch(`${config.apiurl}/Expense/GetCashRetirementByUnit/${sbuid}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getCashRetirementByTeam (managerId) {
  const response = await fetch(`${config.apiurl}/Expense/GetCashRetirementByTeam/${managerId}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getCashRetirementLine (id) {
  const response = await fetch(`${config.apiurl}/Expense/GetCashRetirementLine/${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getCashRetirementLink (id) {
  const response = await fetch(`${config.apiurl}/Expense/GetCashRetirementLink/${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createCashRetirement (data) {
  const response = await fetch(`${config.apiurl}/Expense/PostCashRetirement`, requestOptions.post(data))
  const model = await handleResponse(response)
  return model
}

async function createCashRetirementLine (data) {
  const response = await fetch(`${config.apiurl}/Expense/PostCashRetirementLine`, requestOptions.post(data))
  const model = await handleResponse(response)
  return model
}

async function createCashRetirementLink (data) {
  const response = await fetch(`${config.apiurl}/Expense/PostCashRetirementLink`, requestOptions.post(data))
  const model = await handleResponse(response)
  return model
}

async function updateCashRetirement (data) {
  const response = await fetch(`${config.apiurl}/Expense/PutCashRetirement`, requestOptions.put(data))
  const model = await handleResponse(response)
  return model
}

async function updateCashRetirementLine (data) {
  const response = await fetch(`${config.apiurl}/Expense/PutCashRetirementLine`, requestOptions.put(data))
  const model = await handleResponse(response)
  return model
}

async function updateCashRetirementLink (data) {
  const response = await fetch(`${config.apiurl}/Expense/PutCashRetirementLink`, requestOptions.put(data))
  const model = await handleResponse(response)
  return model
}

async function deleteCashRetirement (id) {
  const response = await fetch(`${config.apiurl}/Expense/deleteCashRetirement/${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function deleteCashRetirementLine (id) {
  const response = await fetch(`${config.apiurl}/Expense/deleteCashRetirementLine/${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function deleteCashRetirementLink (id) {
  const response = await fetch(`${config.apiurl}/Expense/deleteCashRetirementLink/${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}
