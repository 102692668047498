<script setup>
import { authenticationService } from '@/services/AuthenticationService'
import { useRouter } from 'vue-router'
import { useGeneralStore } from '@/stores/GeneralStore'
import { useCoreStore } from '@/stores/CoreStore'
import { useMiscStore } from '@/stores/MiscStore'
import { useSaleStore } from '@/stores/SaleStore'
// import { resetAllPiniaStores } from '@/stores/index'

const router = useRouter()
const generalStore = useGeneralStore()
const coreStore = useCoreStore()
const miscStore = useMiscStore()
const saleStore = useSaleStore()

const currentUser = authenticationService.currentUserValue.user

const logout = async () => {
  authenticationService.logout().then(() => {
    reset()
  })
  await router.push('/login')
}

function reset () {
  generalStore.$reset()
  coreStore.$reset()
  miscStore.$reset()
  saleStore.$reset()
}
</script>
<script>

export default {
  name: 'NavBar',
  data () {
    return {
      sidebarVisible: false
    }
  },
  computed: {
    showToggleButton () {
      console.log('width', window.innerWidth)
      return window.innerWidth <= 1024
    }
  },
  methods: {
    toggleSidebar () {
      const genStore = useGeneralStore()
      genStore.sidebarVisible = !genStore.sidebarVisible
      console.log('sidebar', genStore.sidebarVisible)
      this.$emit('sidebarToggle', genStore.sidebarVisible)
    },
    updateToggleButtonVisibility () {
      const genStore = useGeneralStore()
      if (this.showToggleButton && genStore.sidebarVisible) {
        // Hide the sidebar if the screen becomes smaller than 1024px
        this.sidebarVisible = false
        // Emit an event to notify the SidebarComponent about the change
        this.$emit('sidebarToggle', genStore.sidebarVisible)
      }
    }
  },
  mounted () {
    window.addEventListener('resize', this.updateToggleButtonVisibility)
  }
}
</script>

<template>
  <div class="top-nav-mm w-100">
    <div class="container-fluid">
      <div class="mx-3 my-3 d-flex align-items-center">
        <div class="d-flex align-items-center">
          <button class="btn btn-sm" v-if="showToggleButton" @click="toggleSidebar()">
            <span v-if="!generalStore.sidebarVisible" class="material-symbols-outlined text-white">
            menu
            </span>
            <span v-if="generalStore.sidebarVisible" class="material-symbols-outlined text-white">
            menu_open
            </span>
          </button>
          <span class="nav-text mb-0 px-2">RSE Ship Manager</span>
        </div>
        <div class="d-flex ms-auto justify-content-between">
          <div class="d-flex align-items-center">
            <img class="img-fluid roundec-circle" src="@/assets/img/avatar-one.png" />
            <span class="nav-text px-2">
              {{  currentUser ? currentUser.firstName + ' ' + currentUser.lastName : null }}
            </span>
          </div>
          <a @click="logout()" class="d-flex log-out align-items-center" to="/">
            <img class="img-fluid" src="@/assets/img/icons/logout.png" />
            <span class="logout-text px-2 d-none d-sm-block">Logout</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
