import { authenticationService } from '../services/AuthenticationService'

export default function handleBlobResponse (response) {
  if (!response.ok) {
    return response.text().then(text => {
      const data = text && JSON.parse(text)
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          authenticationService.logout()
          location.reload(true)
        }
        let error = ''
        if (Array.isArray(data)) {
          error = data.join()
        } else {
          error = (data && data.message) || response.statusText
        }
        return Promise.reject(error)
      }
    })
  } else {
    return response.blob().then(blob => {
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          authenticationService.logout()
          location.reload(true)
        }
      }
      console.log(response.headers)
      const fileName = decodeURI(
        // Response.Headers ['content-disposition'] The default is not available, you need to configure in .NET Core Startup.cs
        response.headers.get('content-disposition').split('filename*=UTF-8\'\'')[1]
      )
      return Promise.resolve({ data: blob, fileName: fileName })
    })
  }
}
