import { config } from '../config/index'
import requestOptions from '@/helpers/RequestOptions'
import handleResponse from '@/helpers/HandleResponses'
// import handleBlobResponse from '@/helpers/HandleBlobResponses'
// import handleTextResponse from '@/helpers/HandleTextResponses'

export const coreService = {
  getCustomers,
  getCorporateCustomers,
  getCorporateCustomersById,
  getCustomer,
  getAllocations,
  getTagAllocations,
  getCorporateCustomer,
  getServices,
  getServiceLocations,
  getIServiceLocations,
  getServicePrices,
  getSSPrices,
  getFServicePrices,
  getCFServicePrices,
  getFServicePriceById,
  getCFServicePriceById,
  getCServicePrices,
  getIServicePrices,
  getOnforwardings,
  getDiscounts,
  createCustomer,
  importCustomer,
  importRetailCustomer,
  updateRCustomer,
  updateCorpCustomer,
  generateAllocation,
  fundWallet,
  createCredit,
  createAllocation,
  createTag,
  createDiscount,
  createService,
  createServiceLocation,
  createServicePrice,
  createSSPrice,
  createIServiceLocation,
  createFServicePrice,
  createCServicePrice,
  createIServicePrice,
  createCFServicePrice,
  createOnforwarding,
  updateService,
  updateServiceLocation,
  updateServicePrice,
  updateSSPrice,
  updateIServiceLocation,
  updateFServicePrice,
  updateCServicePrice,
  updateIServicePrice,
  updateCFServicePrice,
  updateOnforwarding,
  updateDiscount,
  removeService,
  removeServiceLocation,
  removeServicePrice,
  removeSSPrice,
  removeIServiceLocation,
  removeFServicePrice,
  removeCServicePrice,
  removeIServicePrice,
  removeCFServicePrice,
  importServicePrice,
  importServiceLocation,
  importSServicePrice,
  importCFServicePrice,
  importFServicePrice,
  importCServicePrice,
  importOnforwardings,
  downloadTemplate,
  removeOnforwarding,
  removeDiscount
}

async function getCustomers (id, name, phone, accountNumber) {
  const response = await fetch(`${config.apiurl}/Customer/customer/${id}?Name=${name}&Phone=${phone}&AccountNumber=${accountNumber}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getCustomer (id) {
  const response = await fetch(`${config.apiurl}/Customer/customerDetail?Id=${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getAllocations (id) {
  const response = await fetch(`${config.apiurl}/Customer/allocations?Id=${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createAllocation (req) {
  const response = await fetch(`${config.apiurl}/Customer/GenerateAllocation`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function getTagAllocations (id) {
  const response = await fetch(`${config.apiurl}/Shipment/tagAllocations?Id=${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createTag (req) {
  const response = await fetch(`${config.apiurl}/Shipment/GenerateTagAllocation`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function getCorporateCustomers (id, code, start, end) {
  const response = await fetch(`${config.apiurl}/Customer/corpCustomers?SbuId=${id}&Code=${code}&Start=${start}&End=${end}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getCorporateCustomersById (id, name, phone, accountNumber) {
  const response = await fetch(`${config.apiurl}/Customer/corpcustomer/${id}?Name=${name}&Phone=${phone}&AccountNumber=${accountNumber}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getCorporateCustomer (id) {
  const response = await fetch(`${config.apiurl}/Customer/corpCustomerDetail?Id=${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createCustomer (allocate, data) {
  const response = await fetch(`${config.apiurl}/Customer?UseAllocated=${allocate}`, requestOptions.post(data))
  const model = await handleResponse(response)
  return model
}

async function importCustomer (sbuId, file) {
  const formData = new FormData()
  formData.append('File', file)
  formData.append('SBUId', sbuId)
  const response = await fetch(`${config.apiurl}/Customer/ImportCustomer`, requestOptions.postForm(formData))
  const id = await handleResponse(response)
  return id
}

async function importRetailCustomer (sbuId, file) {
  const formData = new FormData()
  formData.append('File', file)
  formData.append('SBUId', sbuId)
  const response = await fetch(`${config.apiurl}/Customer/ImportRetailCustomer`, requestOptions.postForm(formData))
  const id = await handleResponse(response)
  return id
}

async function updateRCustomer (req) {
  const response = await fetch(`${config.apiurl}/Customer/UpdateCustomer`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function updateCorpCustomer (req) {
  const response = await fetch(`${config.apiurl}/Customer/UpdateCorpCustomer`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function generateAllocation (data) {
  const response = await fetch(`${config.apiurl}/Customer/GenerateAllocation`, requestOptions.post(data))
  const model = await handleResponse(response)
  return model
}

async function fundWallet (formData) {
  const response = await fetch(`${config.apiurl}/Customer/PostWallet`, requestOptions.postForm(formData))
  const id = await handleResponse(response)
  return id
}

async function createCredit (formData) {
  const response = await fetch(`${config.apiurl}/Customer/PostCredit`, requestOptions.postForm(formData))
  const id = await handleResponse(response)
  return id
}

async function getDiscounts (data) {
  const response = await fetch(`${config.apiurl}/Customer/Discounts`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createDiscount (data) {
  const response = await fetch(`${config.apiurl}/Customer/PostDiscount`, requestOptions.post(data))
  const model = await handleResponse(response)
  return model
}

async function updateDiscount (req) {
  const response = await fetch(`${config.apiurl}/Customer/UpdateDiscount`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function removeDiscount (id) {
  const response = await fetch(`${config.apiurl}/Customer/removeDiscount?id=${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function getServices (id) {
  const response = await fetch(`${config.apiurl}/Service/Services?Id=${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createService (req) {
  const response = await fetch(`${config.apiurl}/Service/PostService`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function updateService (req) {
  const response = await fetch(`${config.apiurl}/Service/UpdateService`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function removeService (id) {
  const response = await fetch(`${config.apiurl}/Service/removeService?id=${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function getServiceLocations (id, loc) {
  const response = await fetch(`${config.apiurl}/Service/ServiceLocations/${loc}?Id=${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createServiceLocation (req) {
  const response = await fetch(`${config.apiurl}/Service/PostServiceLocation`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function updateServiceLocation (req) {
  const response = await fetch(`${config.apiurl}/Service/UpdateServiceLocation`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function removeServiceLocation (id) {
  const response = await fetch(`${config.apiurl}/Service/removeServiceLocation?id=${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function getServicePrices (id) {
  const response = await fetch(`${config.apiurl}/Service/ServicePrices?Id=${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createServicePrice (req) {
  const response = await fetch(`${config.apiurl}/Service/PostServicePrice`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function updateServicePrice (req) {
  const response = await fetch(`${config.apiurl}/Service/updateServicePrice`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function removeServicePrice (id) {
  const response = await fetch(`${config.apiurl}/Service/removeServicePrice?id=${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function getSSPrices (sbuId, orgId, destId) {
  const response = await fetch(`${config.apiurl}/Service/SServicePrices?sbuId=${sbuId}&orgId=${orgId}&destId=${destId}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createSSPrice (req) {
  const response = await fetch(`${config.apiurl}/Service/PostSServicePrice`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function updateSSPrice (req) {
  const response = await fetch(`${config.apiurl}/Service/UpdateSServicePrice`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function removeSSPrice (id) {
  const response = await fetch(`${config.apiurl}/Service/removeSServicePrice?id=${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function getIServiceLocations (id) {
  const response = await fetch(`${config.apiurl}/Service/IServiceLocations?Id=${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createIServiceLocation (req) {
  const response = await fetch(`${config.apiurl}/Service/PostIServiceLocation`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function updateIServiceLocation (req) {
  const response = await fetch(`${config.apiurl}/Service/UpdateIServiceLocation`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function removeIServiceLocation (id) {
  const response = await fetch(`${config.apiurl}/Service/removeIServiceLocation?id=${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function getFServicePrices () {
  const response = await fetch(`${config.apiurl}/Service/FServicePrices`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getFServicePriceById (id) {
  const response = await fetch(`${config.apiurl}/Service/FServicePriceById?Id=${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createFServicePrice (req) {
  const response = await fetch(`${config.apiurl}/Service/PostFServicePrice`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function updateFServicePrice (req) {
  const response = await fetch(`${config.apiurl}/Service/UpdateFServicePrice`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function removeFServicePrice (id) {
  const response = await fetch(`${config.apiurl}/Service/removeFServicePrice?id=${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function importFServicePrice (req) {
  const response = await fetch(`${config.apiurl}/Service/ImportFServicePrice`, requestOptions.postForm(req))
  const model = await handleResponse(response)
  return model
}

async function getCServicePrices (id) {
  const response = await fetch(`${config.apiurl}/Service/CServicePrices?Id=${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createCServicePrice (req) {
  const response = await fetch(`${config.apiurl}/Service/PostCServicePrice`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function updateCServicePrice (req) {
  const response = await fetch(`${config.apiurl}/Service/UpdateCServicePrice`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function removeCServicePrice (id) {
  const response = await fetch(`${config.apiurl}/Service/removeCServicePrice?id=${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function getIServicePrices (id) {
  const response = await fetch(`${config.apiurl}/Service/IServicePrices?Id=${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createIServicePrice (req) {
  const response = await fetch(`${config.apiurl}/Service/PostIServicePrice`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function updateIServicePrice (req) {
  const response = await fetch(`${config.apiurl}/Service/UpdateIServicePrice`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function removeIServicePrice (id) {
  const response = await fetch(`${config.apiurl}/Service/removeIServicePrice?id=${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function getCFServicePrices (id) {
  const response = await fetch(`${config.apiurl}/Service/CFServicePrices?Id=${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getCFServicePriceById (id) {
  const response = await fetch(`${config.apiurl}/Service/CFServicePriceById?Id=${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createCFServicePrice (req) {
  const response = await fetch(`${config.apiurl}/Service/PostCFServicePrice`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function updateCFServicePrice (req) {
  const response = await fetch(`${config.apiurl}/Service/UpdateCFServicePrice`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function removeCFServicePrice (id) {
  const response = await fetch(`${config.apiurl}/Service/removeCFServicePrice?id=${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function importServicePrice (data) {
  const response = await fetch(`${config.apiurl}/Service/ImportServicePrice`, requestOptions.postForm(data))
  const model = await handleResponse(response)
  return model
}

async function importServiceLocation (data) {
  const response = await fetch(`${config.apiurl}/Service/ImportServiceLocation`, requestOptions.postForm(data))
  const model = await handleResponse(response)
  return model
}

async function importSServicePrice (data) {
  const response = await fetch(`${config.apiurl}/Service/ImportSServicePrice`, requestOptions.postForm(data))
  const model = await handleResponse(response)
  return model
}

async function importCFServicePrice (cId, data) {
  const response = await fetch(`${config.apiurl}/Service/ImportCFServicePrice/${cId}`, requestOptions.postForm(data))
  const model = await handleResponse(response)
  return model
}

async function importCServicePrice (cId, data) {
  const response = await fetch(`${config.apiurl}/Service/ImportCServicePrice/${cId}`, requestOptions.postForm(data))
  const model = await handleResponse(response)
  return model
}

async function downloadTemplate (req) {
  const response = await fetch(`${config.apiurl}/Service/`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function getOnforwardings (sbuId, cityId) {
  const response = await fetch(`${config.apiurl}/Misc/Onforwardings?sbuId=${sbuId}&cityId=${cityId}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createOnforwarding (req) {
  const response = await fetch(`${config.apiurl}/Misc/PostOnforwarding`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function updateOnforwarding (req) {
  const response = await fetch(`${config.apiurl}/Misc/UpdateOnforwarding`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function removeOnforwarding (id) {
  const response = await fetch(`${config.apiurl}/Misc/removeOnforwarding/${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function importOnforwardings (cId, data) {
  const response = await fetch(`${config.apiurl}/Misc/ImportOnforwarding`, requestOptions.postForm(data))
  const model = await handleResponse(response)
  return model
}
