<template>
  <section class="body vh-100">
    <div class="login-nav w-100">
      <div class="p-2">
        <p class="nav-text mb-0">RSE Ship Manager</p>
      </div>
    </div>
    <div class="h-100 sign-up-form d-flex align-items-center">
      <div class="col-sm-7 col-lg-6 col-xl-4 mx-auto">
        <div class="form-div w-100">
          <div class="cl p-4">
            <div class="text-center">
              <p class="form-div-title">Login to RSM Portal</p>
              <p class="form-div-sub">Please login with your information</p>
              <p class="form-error">{{ error }}</p>
            </div>
            <Form class="" @submit="onSubmit()" :validation-schema="schema" v-slot="{ errors }">
              <div class="form-label-group in-border mb-4">
                <label for="email" class="px-0 form-label login-label">Email</label>
                <Field type="email" name="email" id="email" v-model="email" class="form-control login-input form-control-lg shadow-none"  placeholder="Email address" />
                <p class="form-error mb-0">{{ errors.email }}</p>
              </div>
              <div class="form-label-group in-border mb-4">
                <div class="d-flex justify-content-between">
                  <label for="password" class="px-0 form-label login-label">Password</label>
                  <router-link to="/forgot-password" class="forgot">
                    Forgot Password?
                  </router-link>
                </div>
                <Field type="password" name="password" v-model="password" id="password" class="form-control login-input form-control-lg shadow-none"  placeholder="Password (min. 8 characters)" />
                <p class="form-error mb-0">{{ errors.password }}</p>
              </div>
              <div class="form-check mb-4 d-flex align-items-center">
                <input class="form-check-input" type="checkbox" value="" id="remember">
                <label class="form-check-label remember px-2" for="remember">
                  Remember Me
                </label>
              </div>
              <div class="form-div-foot mb-3">
                <button type="submit" class="btn btn-lg cont-btn w-100" v-if="!loading">
                  Sign in
                </button>
                <button type="button" class="btn btn-lg cont-btn w-100 disabled" v-if="loading">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
              </div>
              <!-- <div class="text-center">
                <p class="sign-up">
                  Don't have an account? <a class="sign-up">Sign up now</a>
                </p>
              </div> -->
            </Form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Form, Field } from 'vee-validate'
import { authenticationService } from '../services/AuthenticationService'

export default {
  name: 'LoginView',
  components: {
    Form,
    Field
  },
  data () {
    return {
      email: '',
      password: '',
      loading: false,
      error: null
    }
  },
  methods: {
    onSubmit () {
      this.loading = true
      authenticationService.login(this.email, this.password)
        .then(
          u => {
            console.log(u)
            setTimeout(() => {
              if (u.user?.roles[0] !== 'IT Administrator') {
                this.$router.push('/dashboard')
              } else {
                this.$router.push('/admin/subsidiaries/index')
              }
            }, 1000)
          },
          error => {
            this.error = error
            this.loading = false
          }
        )
    }
  }
}
</script>

<script setup>

const schema = {
  email: 'required',
  password: 'required|minLength:8'
}
</script>

<style scoped>
.form-error {
  font-size: 14px;
  font-weight: 500;
  color: rgb(255, 0, 0);
  line-height: 21px;
}
</style>
