import { defineStore, acceptHMRUpdate } from 'pinia'
import { authenticationService } from '../services/AuthenticationService'
import { miscService } from '../services/MiscService'
import { coreService } from '../services/CoreService'
import { useRouter } from 'vue-router'

const router = useRouter()

export const useGeneralStore = defineStore('GeneralStore', {
  state: () => {
    return {
      currentUser: authenticationService.currentUserValue ? authenticationService.currentUserValue.user : null,
      subsidiaries: [],
      users: [],
      subsidiaryId: authenticationService.currentUserValue.user.sbuUser ? authenticationService.currentUserValue.user.sbuUser.sbuId : null,
      name: '',
      phoneNumber: '',
      accountNumber: '',
      cStart: 0,
      cEnd: 100,
      cCode: '',
      searchParam: '',
      customers: [],
      corpCustomers: [],
      customer: {},
      corpCustomer: {},
      admins: [],
      admin: {},
      newAdmin: {
        sbuId: null
      },
      sbuId: null,
      adminId: '',
      roles: [],
      newRole: {},
      Payment: {
        CustomerId: '',
        BankAccountId: null,
        ReceiptNo: '',
        ReceiptFile: '',
        Reference: '',
        Amount: 0,
        Type: '',
        Customer: {},
        createdOn: new Date()
      },
      Credit: {},
      fileImport: '',
      fundFile: null,
      fundFileName: [],
      servicePrices: [],
      price: {},
      newPrice: {
        sbuId: null,
        serviceId: null
      },
      priceId: '',
      services: [],
      discounts: [],
      discount: {},
      newDiscount: {},
      discAtt: {},
      discType: [
        { value: 0, name: 'Please Select' },
        { value: 1, name: 'Percentage' },
        { value: 2, name: 'Amount' }
      ],
      rewardBy: [
        { value: 0, name: 'Please Select' },
        { value: 1, name: 'Points' },
        { value: 2, name: 'Order' }
      ],
      discOrderType: [
        { value: 0, name: 'Please Select' },
        { value: 1, name: 'First Transaction' },
        { value: 2, name: 'Average Transactions' },
        { value: 3, name: 'Total Transactions' }
      ],
      discountBy: 0,
      discInfoView: false,
      discAttView: false,
      discountId: '',
      email: '',
      password: '',
      confirmPassword: '',
      code: '',
      currentPassword: '',
      newPassword: '',
      passChange: {},
      template: '',
      message: '',
      fpOne: true,
      fpTwo: false,
      smsOne: true,
      smsTwo: false,
      loading: false,
      viewModal: false,
      deleteModal: false,
      action: false,
      actionType: 'topup',
      searching: false,
      topup: false,
      sidebarVisible: false,
      error: null
    }
  },
  getters: {
    filteredCustomers: (state) => {
      let customersFiltered = state.customers
      if (state.searchParam !== '' && state.searchParam) {
        customersFiltered = customersFiltered.filter((p) => {
          const firstName = p.firstName?.toLowerCase() ?? ''
          const lastName = p.lastName?.toLowerCase() ?? ''
          const otherName = p.otherName?.toLowerCase() ?? ''
          const companyName = p.companyName?.toLowerCase() ?? ''
          const accountNo = p.accountNo?.toLowerCase() ?? ''
          return firstName?.includes(state.searchParam) || lastName?.includes(state.searchParam) || otherName.includes(state.searchParam) || companyName?.includes(state.searchParam) || accountNo?.includes(state.searchParam)
        })
      }
      return customersFiltered
    },
    corporateCustomers: (state) => {
      let corpCustomers = state.corpCustomers
      if (state.searchParam !== '' && state.searchParam) {
        corpCustomers = corpCustomers.filter((c) => {
          const companyName = c.companyName.toLowerCase()
          return companyName.includes(state.searchParam)
        })
      }
      return corpCustomers
    },
    filteredDiscounts: (state) => {
      let discountFiltered = state.discounts
      if (state.searchParam !== '' && state.searchParam) {
        discountFiltered = discountFiltered.filter((d) => {
          const discountName = d.discountName.toLowerCase()
          return discountName.includes(state.searchParam)
        })
      }
      return discountFiltered
    },
    filteredAdmins: (state) => {
      let adminsFiltered = state.admins
      if (state.searchParam !== '' && state.searchParam) {
        adminsFiltered = adminsFiltered.filter((p) => {
          const firstName = p.firstName?.toLowerCase()
          const lastName = p.lastName?.toLowerCase()
          return firstName?.includes(state.searchParam) || lastName?.includes(state.searchParam)
        })
      }
      return adminsFiltered
    }
    // filteredGAdmins: (state) => {
    //   let adminsFiltered = state.admins
    //   if (state.searchParam !== '' && state.searchParam) {
    //     adminsFiltered = adminsFiltered.filter((p) => {
    //       const firstName = p.firstName?.toLowerCase()
    //       const lastName = p.lastName?.toLowerCase()
    //       return firstName?.includes(state.searchParam) || lastName?.includes(state.searchParam)
    //     })
    //   }
    //   return adminsFiltered
    // }
  },
  actions: {
    getSubsidiaries () {
      miscService.getSBUs()
        .then(
          s => {
            this.subsidiaries = s
            console.log('subs >>', s)
          }
        )
    },
    getCustomers () {
      this.searching = true
      coreService.getCustomers(this.subsidiaryId, this.name, this.phoneNumber, this.accountNumber)
        .then(
          c => {
            if (Array.isArray(c)) {
              this.customers = c
            } else {
              this.customers = []
              this.customers.push(c)
            }
            console.log('customers >>', c)
            this.searching = false
          },
          error => {
            this.error = error
            this.searching = false
          }
        )
    },
    getCustomerDetail (id) {
      coreService.getCustomer(id)
        .then(
          c => {
            this.customer = c
            console.log('cus', c)
          },
          error => {
            alert(error)
            console.log(error)
          }
        )
    },
    getCorpCustomers (id) {
      this.searching = true
      coreService.getCorporateCustomers(id, this.cCode, this.cStart, this.cEnd)
        .then(
          cp => {
            console.log('corpCus >>', cp)
            if (Array.isArray(cp)) {
              this.corpCustomers = cp
            } else {
              this.corpCustomers = []
              this.corpCustomers.push(cp)
            }
            this.searching = false
          },
          error => {
            this.error = error
            this.searching = false
            console.log(error)
            // alert(error)
          }
        )
    },
    getCorpCustomersById (id) {
      this.searching = true
      coreService.getCorporateCustomersById(id, this.name, this.phoneNumber, this.accountNumber)
        .then(
          cp => {
            console.log('corpCus >>', cp)
            router.push(`/corporate-customers/details/${cp.id}`)
            this.searching = false
          },
          error => {
            this.error = error
            console.log(error)
            this.searching = false
            alert(error)
          }
        )
    },
    getCorpCustomerDetail (id) {
      coreService.getCorporateCustomer(id)
        .then(
          c => {
            this.corpCustomer = c
            console.log('cus', c)
          },
          error => {
            alert(error)
            console.log(error)
          }
        )
    },
    getCServicePrices (id) {
      this.loading = true
      coreService.getCServicePrices(id)
        .then(
          csP => {
            console.log('servicePrices >>', csP)
            this.servicePrices = csP
            this.loading = false
          },
          e => {
            console.log(e)
            alert(e)
            this.loading = false
          }
        )
    },
    getDiscounts () {
      this.loading = true
      coreService.getDiscounts()
        .then(
          d => {
            console.log('discounts >>', d)
            this.discounts = d
            this.loading = false
          },
          e => {
            console.log(e)
            alert(e)
            this.loading = false
          }
        )
    },
    getAdmins (role) {
      this.loading = true
      miscService.getAdminUsers(role)
        .then(
          u => {
            console.log('users >>', u)
            this.admins = u
            this.loading = false
          },
          e => {
            console.log(e)
            alert(e)
            this.loading = false
          }
        )
    },
    getAllRoles () {
      this.loading = true
      miscService.getRoles()
        .then(
          r => {
            console.log('roles >>', r)
            this.roles = r
            this.loading = false
          },
          e => {
            console.log(e)
            alert(e)
            this.loading = false
          }
        )
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGeneralStore, import.meta.hot))
}
