<template>
  <section class="body vh-100">
    <div class="login-nav w-100">
      <div class="p-2">
        <p class="nav-text mb-0">RSE Ship Manager</p>
      </div>
    </div>
    <div class="h-100 sign-up-form d-flex align-items-center">
      <div class="col-sm-7 col-lg-6 col-xl-4 mx-auto">
        <div class="form-div w-100" v-if="generalStore.fpOne === true">
          <div class="cl p-4">
            <div class="text-center">
              <p class="form-div-title">Forgot Password?</p>
              <p class="form-div-sub text-start">Please enter the mail associated to your account below. A code will be sent to your mail</p>
              <p class="form-error">{{ error }}</p>
            </div>
            <Form class="" @submit="sendRecovery()" :validation-schema="codeSchema" v-slot="{ errors }">
              <div class="form-label-group in-border mb-5">
                <label for="email" class="px-0 form-label login-label">Email</label>
                <Field type="email" name="email" id="email" v-model="generalStore.email" class="form-control login-input form-control-lg shadow-none"  placeholder="Email address" />
                <p class="form-error mb-0">{{ errors.email }}</p>
              </div>
              <div class="form-div-foot mb-3">
                <button type="submit" class="btn btn-lg cont-btn w-100" v-if="!generalStore.loading">
                  Send Code
                </button>
                <button type="button" class="btn btn-lg cont-btn w-100 disabled" v-if="generalStore.loading">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
              </div>
            </Form>
          </div>
        </div>
        <div class="form-div w-100" v-if="generalStore.fpTwo === true">
          <div class="cl p-4">
            <div class="text-center">
              <p class="form-div-title">Forgot Password?</p>
              <p class="form-div-sub text-start">Please enter your new password and the code sent to your mail</p>
            </div>
            <Form class="" @submit="reset()" :validation-schema="schema" v-slot="{ errors }">
              <div class="form-label-group in-border mb-4">
                <label for="email" class="px-0 form-label login-label">Email</label>
                <Field type="email" name="email" id="email" disabled v-model="generalStore.email" class="form-control login-input form-control-lg shadow-none"  placeholder="Email address" />
                <p class="form-error mb-0">{{ errors.email }}</p>
              </div>
              <div class="form-label-group in-border mb-4">
                <div class="d-flex justify-content-between">
                  <label for="password" class="px-0 form-label login-label">Password</label>
                </div>
                <Field type="password" name="password" v-model="generalStore.password" id="password" class="form-control login-input form-control-lg shadow-none"  placeholder="Password (min. 8 characters)" />
                <p class="form-error mb-0">{{ errors.password }}</p>
              </div>
              <div class="form-label-group in-border mb-4">
                <div class="d-flex justify-content-between">
                  <label for="confirmPassword" class="px-0 form-label login-label">Confirm Password</label>
                </div>
                <Field type="password" name="confirmPassword" v-model="generalStore.confirmPassword" id="password" class="form-control login-input form-control-lg shadow-none"  placeholder="Password (min. 8 characters)" />
                <p class="form-error mb-0">{{ errors.confirmPassword }}</p>
              </div>
              <div class="form-label-group in-border mb-4">
                <label for="code" class="px-0 form-label login-label">Code</label>
                <Field type="text" name="code" id="code" v-model="generalStore.code" class="form-control login-input form-control-lg shadow-none"  placeholder="Email address" />
                <p class="form-error mb-0">{{ errors.code }}</p>
              </div>
              <div class="form-div-foot mb-3">
                <button type="submit" class="btn btn-lg cont-btn w-100" v-if="!generalStore.loading">
                  Confirm
                </button>
                <button type="button" class="btn btn-lg cont-btn w-100 disabled" v-if="generalStore.loading">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Form, Field } from 'vee-validate'
import { authenticationService } from '../services/AuthenticationService'
import { useGeneralStore } from '@/stores/GeneralStore'
import { useRouter } from 'vue-router'

export default {
  name: 'ForgotPassword',
  components: {
    Form,
    Field
  },
  data () {
    return {
      email: '',
      password: '',
      loading: false,
      error: null
    }
  },
  methods: {
    onSubmit () {
      this.loading = true
      authenticationService.login(this.email, this.password)
        .then(
          u => {
            console.log(u)
            this.$router.push('/dashboard')
          },
          error => {
            this.error = error
            this.loading = false
          }
        )
    }
  }
}
</script>

<script setup>
const generalStore = useGeneralStore()
const router = useRouter()

function sendRecovery () {
  generalStore.loading = true
  authenticationService.sendRecovery(generalStore.email)
    .then(
      r => {
        generalStore.code = r
        generalStore.loading = false
        alert('An email containing reset code has been sent to your mail')
        generalStore.fpOne = false
        generalStore.fpTwo = true
      },
      e => {
        generalStore.error = e
        alert(e)
        generalStore.loading = false
      }
    )
}

function reset () {
  generalStore.loading = true
  authenticationService.resetPassword()
    .then(
      r => {
        console.log(r)
        alert('Password reset successfull')
        generalStore.loading = false
        generalStore.fpOne = true
        generalStore.fpTwo = false
        router.push('/login')
      },
      e => {
        console.log(e)
        alert(e)
        generalStore.loading = false
      }
    )
}

const schema = {
  email: 'required',
  password: 'required|minLength:8',
  confirmPassword: 'confirmed:@password',
  code: 'required'
}
const codeSchema = {
  email: 'required'
}
</script>

<style scoped>
.form-error {
  font-size: 14px;
  font-weight: 500;
  color: rgb(255, 0, 0);
  line-height: 21px;
}
</style>
