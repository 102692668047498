import { authenticationService } from '../services/AuthenticationService'

export default function handleResponse (response) {
  return response.text().then(text => {
    // console.log('response.text', text)
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if ([401, 403].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        authenticationService.logout()
        location.reload(true)
      }
      let error = ''
      if (Array.isArray(data)) {
        error = data.join()
      } else {
        error = (data && data.message) || response.statusText
      }
      return Promise.reject(error)
    }
    return data
  })
}
